import React, { useContext } from 'react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Avatar, useTheme } from '@mui/material'
import Link from '@mui/material/Link'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import IconButton from '@mui/material/IconButton'

import { PillarboxTheme } from '../theme'
import { AuthContext } from '../Auth'

const APP_VERSION = process.env.REACT_APP_VERSION ?? ''

const StyledUserCardPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey[700]
}))

const StyledUserAvatarButtonBase = styled(ButtonBase)(() => ({
  display: 'flex',
  flex: 1,
  maxWidth: '25%',
  pointerEvents: 'none'
}))

const StyledTitleBox = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end'
}))

const StyledVersionTextTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[500]
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper
}))

const StyledThemeToggleIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1)
}))

export const Nav = (): JSX.Element => {
  const { profilePicLink, userFullName, email, unauthenticate } = useContext(AuthContext)
  const { palette, togglePrefersDarkMode } = useTheme<PillarboxTheme>()

  const logOut = (): void => {
    window.localStorage.setItem('authenticated', 'false')
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('jwt_decoded')
    unauthenticate()
  }

  return (
    <AppBar position="static">
      <StyledToolbar>
        <StyledTitleBox>
          <Typography variant="h4">
            <Link href="/eips" color="inherit" underline="none">
              EIP Dashboard
            </Link>
          </Typography>

          <StyledVersionTextTypography variant="caption">
            {APP_VERSION}
          </StyledVersionTextTypography>
        </StyledTitleBox>
        <StyledUserCardPaper>
          <StyledUserAvatarButtonBase>
            <Avatar src={profilePicLink ?? ''} />
          </StyledUserAvatarButtonBase>
          <div style={{ marginLeft: '8px' }}>
            <Typography variant="subtitle1">
              {userFullName}
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              {email}
            </Typography>
          </div>
        </StyledUserCardPaper>
        <StyledThemeToggleIconButton
          color="inherit"
          onClick={togglePrefersDarkMode}
          aria-label="Toggle light/dark theme"
        >
          {palette.mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </StyledThemeToggleIconButton>
        <Button
          color="inherit"
          onClick={logOut}>
            Log out
        </Button>
      </StyledToolbar>
    </AppBar>
  )
}

export default Nav
