import React from 'react'
import EIPTable from '../EipTable'
import Nav from '../Nav'
import Grid from '@mui/material/Grid2'
import { styled } from '@mui/material/styles'

const StyledGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3)
}))

export const Dashboard = (): JSX.Element => {
  return (
    <>
      <Nav />
      <Grid container justifyContent="center" >
        <StyledGrid size={{ xs: 8 }}>
          <EIPTable />
        </StyledGrid>
      </Grid>
    </>
  )
}

export default Dashboard
