import { MutableRefObject, useRef, useEffect } from 'react'

export const useIsMounted = (): MutableRefObject<boolean> => {
  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true

    return (): void => { isMountedRef.current = false }
  })

  return isMountedRef
}
