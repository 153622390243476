import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { AuthContext } from '../Auth'
import { PassThroughState } from '../components/LogInToGoogle'

const ProtectedRoute = (): JSX.Element => {
  const { isAuthenticated } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/', { replace: true, state: { userRedirect: location.pathname } as PassThroughState })
    }
  }, [isAuthenticated, navigate])

  return <Outlet />
}

export default ProtectedRoute
