import React, { useState } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import { styled } from '@mui/material/styles'
import { green } from '@mui/material/colors'
import { useIsMounted } from './Mounted'

const ContentCopyIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props}>
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
  </SvgIcon>

const writeTextToClipboard = (textToCopy: string): Promise<void> => navigator.clipboard.writeText(textToCopy)

const StyledCopyToClipboardIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const StyledDoneIcon = styled(DoneIcon)(() => ({
  color: green[600]
}))

interface Props {
    copyText: string;
}

const CopyToClipboard = ({ copyText }: Props): JSX.Element => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const isMounted = useIsMounted()

  const handleCopy = (): void => {
    (async () => {
      await writeTextToClipboard(copyText)
      setShowConfirmation(true)
      setTimeout(() => {
        if (isMounted.current) setShowConfirmation(false)
      }, 1000)
    })()
  }

  return (
    <>
      <StyledCopyToClipboardIconButton aria-label="copy to clipboard" disabled={showConfirmation} onClick={handleCopy}>
        {!showConfirmation
          ? <ContentCopyIcon />
          : <StyledDoneIcon />}
      </StyledCopyToClipboardIconButton>
    </>
  )
}

export default CopyToClipboard
