/* eslint-disable camelcase */
export interface NATGateway {
  id: string;
  link: string;
  mta_hostname?: string;
  mta_link?: string;
  subnet_ids?: string[];
  subnets_link?: string;
}

export interface EIPSummary {
  address: string;
  address_link: string;
  nat_gateway?: NATGateway;
  listed_status: ListedLevel;
}

export interface RBL {
  name?: string;
  host: string;
  website?: string;
  delist_site?: string;
}

export enum ListedLevel {
  NotListed = 0,
  Warning = 100,
  Listed = 200
}

export interface Reputation {
  listed: ListedLevel;
  text: string;
  last_updated: Date;
  rbl: RBL;
}

export interface EIPDetail {
  address: string;
  address_link: string;
  nat_gateway?: NATGateway;
  reputations: Reputation[];
}

export interface EIPEvent {
  message_id: string;
  event_time: Date;
  payload: string;
  topic_arn: string;
}
/* eslint-enable camelcase */
