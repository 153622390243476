const IPV4Octets = 4

const isIntegerBetween = (val: string, min: number, max: number): boolean => {
  const num = parseInt(val)
  if (isNaN(num)) {
    return false
  }

  if (!val.match(/^\d+$/g)) {
    return false
  }

  return num >= min && num <= max
}

interface IPRange {
    networkPrefix: string
    prefixLength: number
}

export const parseIPRange = (inputValue: string): IPRange => {
  const withPrefixLength = inputValue.trim().split('/')
  if (withPrefixLength.length !== 2) {
    throw Error("does not specify IP and prefix length separeted by '/'")
  }

  if (!isIntegerBetween(withPrefixLength[1], 1, 32)) {
    throw Error('prefix length is not number between 1 and 32')
  }

  const ipParts = withPrefixLength[0].split('.')
  // IPV4 has 4 octets
  if (ipParts.length !== IPV4Octets) {
    throw Error(`Invalid IPv4 address, but be 4 octets in length, found ${ipParts.length}`)
  }

  if (!ipParts.every((p: string) => isIntegerBetween(p, 0, 255))) {
    throw Error('All IP octets must be between 0 and 255')
  }

  return {
    networkPrefix: withPrefixLength[0],
    prefixLength: parseInt(withPrefixLength[1])
  }
}

export const isIPRange = (inputValue: string): boolean => {
  try {
    parseIPRange(inputValue)
    return true
  } catch {
    return false
  }
}

export const isPartialIP = (inputValue: string): boolean => {
  if (inputValue.trim() === '') {
    return false
  }

  const parts = inputValue.trim().split('.')
  // IPV4 has max 4 octets
  if (parts.length > IPV4Octets) {
    return false
  }

  if (!parts.every((p: string, i: number) => {
    if (p === '' && i === parts.length - 1) { // Last octect can be empty
      return true
    }

    return isIntegerBetween(p, 0, 255)
  })) {
    return false
  }

  return true
}

export const expandToIPAddr = (partialAddress: string): string => {
  const parts = partialAddress.split('.').filter(p => p !== '')
  const inputLength = parts.length

  for (let i = 0; i < IPV4Octets - inputLength; i++) {
    parts.push('0')
  }

  return parts.join('.')
}

export const unusedBits = (ipAddr: string): number => {
  const parts = ipAddr.split('.')
  let unusedBits = 0
  for (let i = parts.length - 1; i >= 0; i--) {
    if (parts[i] === '0') {
      unusedBits += 8
      continue
    }

    const num = parseInt(parts[i])
    if (isNaN(num)) {
      console.log(`IP parse error, part ${i} is NaN`, parts)
      break
    }

    for (let j = 0; j < 8; j++) {
      if ((num & (1 << j)) !== 0) {
        return unusedBits
      } else {
        unusedBits++
      }
    }
  }

  return unusedBits
}
