import React, { useContext, useEffect } from 'react'
import logo from '../../static/logo.png'
import Button from '@mui/material/Button'
import { LogInToGoogle, PassThroughState } from '../LogInToGoogle'

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../Auth'

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  fontWeight: 400,
  color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.info.light
}))

const StyledLogo = styled('img')(() => ({
  height: '40vmin',
  pointerEvents: 'none'
}))

const StyledLandingBox = styled(Box)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const Landing = (): JSX.Element => {
  const { isAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const LogIn = (): void => LogInToGoogle(location.state as PassThroughState)

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/eips')
    }
  }, [isAuthenticated, navigate])

  return (
    <StyledLandingBox>
      <StyledLogo src={logo} alt="logo" />
      <StyledTitle variant="h2">Pillarbox</StyledTitle>
      <Button variant="contained" color="primary" onClick={LogIn}>Log in with Google</Button>
    </StyledLandingBox>
  )
}

export default Landing
