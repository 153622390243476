import React, { useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthContextProvider } from './Auth'
import Pages from './routes/Pages'
import { useThemePreference, PillarboxThemeOptions } from './theme'

import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

export const App = (): JSX.Element => {
  const [prefersDarkMode, togglePrefersDarkMode] = useThemePreference()
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light'
      },
      togglePrefersDarkMode
    } as PillarboxThemeOptions), [prefersDarkMode, togglePrefersDarkMode])

  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Pages />
        </BrowserRouter>
      </ThemeProvider>
    </AuthContextProvider>
  )
}

export default App
