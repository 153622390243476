import { useState } from 'react'

import { Columns, EIPTableRow } from './Table'

// E.G. "192.168.0.1" => 192168000001
const paddedNumberIP = (ip: string): number => Number(ip.split('.').map(s => s.padStart(3, '0')).join(''))

type labelComparator = (a: string, b: string) => number

const textComparator = (a: string, b: string): number => a.localeCompare(b)
const ipComparator = (a: string, b: string): number => paddedNumberIP(a) - paddedNumberIP(b)

const specialComparatorMap = new Map<keyof Columns, labelComparator>()
specialComparatorMap.set('address', ipComparator)

interface SortHook {
  sortedTableData: EIPTableRow[];
  updateSorting: (id: keyof Columns) => void;
  order: 'asc' | 'desc';
  orderBy: keyof Columns;
}

export const useSort = (unsortedTableData: EIPTableRow[]): SortHook => {
  const [orderBy, setOrderBy] = useState<keyof Columns>('address')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const selectField = (r: EIPTableRow): string => r.get(orderBy)?.label ?? ''

  const ascendingComparator = (id: keyof Columns): labelComparator => specialComparatorMap.get(id) ?? textComparator

  const comparator = order === 'asc'
    ? (a: EIPTableRow, b: EIPTableRow): number => ascendingComparator(orderBy)(selectField(a), selectField(b))
    : (a: EIPTableRow, b: EIPTableRow): number => -ascendingComparator(orderBy)(selectField(a), selectField(b))

  const updateSorting = (id: keyof Columns): void => {
    const isAsc = orderBy === id && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(id)
  }

  const sortedTableData = unsortedTableData.sort(comparator)

  return {
    sortedTableData,
    updateSorting,
    order,
    orderBy
  }
}
