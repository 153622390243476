import React from 'react'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '80vh'
}))

const NotFound = (): JSX.Element => {
  return (
    <StyledBox>
      <Typography variant="h1">404: Not Found</Typography>
      <Link href="/">Return to Home</Link>
    </StyledBox>
  )
}

export default NotFound
