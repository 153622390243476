import React, { createContext, useReducer, useEffect } from 'react'
import { LogInToGoogle } from './components/LogInToGoogle'

/* eslint-disable camelcase */
export interface UserInfo {
  nonce: string;
  email: string;
  picture: string;
  given_name: string;
  family_name: string;
  exp: number;
}
/* eslint-enable camelcase */

interface AuthState {
  isAuthenticated: boolean | null;
  userFullName: string | null;
  profilePicLink: string | null;
  email: string | null;
}

interface AuthStateMutations {
  authenticate: (token: string, userInfo: UserInfo) => void;
  unauthenticate: () => void;
}

export type MutableAuthState = AuthState & AuthStateMutations

const initialState = {
  isAuthenticated: null,
  userFullName: null,
  profilePicLink: null,
  email: null,

  authenticate: (): void => {
    // Do nothing
  },
  unauthenticate: (): void => {
    // Do nothing
  }
}

export const AuthContext = createContext<MutableAuthState>(initialState)

type AuthAction =
| { type: 'authenticate'; token: string; userInfo: UserInfo | null }
| { type: 'unauthenticate' };

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
  case 'authenticate':
  {
    if (action.userInfo !== null) {
      const email = action.userInfo.email
      const profilePicLink = action.userInfo.picture
      const userFullName = action.userInfo.given_name + ' ' + action.userInfo.family_name
      return { ...state, email, profilePicLink, userFullName, isAuthenticated: true }
    }

    return { ...state, email: 'loading...', profilePicLink: null, userFullName: 'loading...', isAuthenticated: true }
  }
  case 'unauthenticate':
    return { ...state, email: null, profilePicLink: null, userFullName: null, isAuthenticated: false }
  default:
    return state
  }
}

const milisecondsPerSecond = 1000
// Convert an epoch time given in seconds to one in miliseconds
const timeInMiliseconds = (seconds: number): number => seconds * milisecondsPerSecond
// Checks if a given epoch time (in seconds) has been passed
const timeExceeded = (epochTime: number): boolean => Date.now() >= timeInMiliseconds(epochTime)

interface ProviderProps {
  children: React.ReactNode;
}

export const LogOut = (): void => {
  window.localStorage.setItem('authenticated', 'false')
  window.localStorage.removeItem('id_token')
  window.localStorage.removeItem('jwt_decoded')
  window.localStorage.removeItem('nonce')
}

export const AuthContextProvider = (props: ProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const authenticate = (jwtToken: string, userInfo: UserInfo): void => dispatch({ type: 'authenticate', token: jwtToken, userInfo })
  const unauthenticate = (): void => dispatch({ type: 'unauthenticate' })

  useEffect(() => {
    const isAuth = window.localStorage.getItem('authenticated') === 'true'
    const token = window.localStorage.getItem('id_token')
    // check if JWT is expired, log user out if expired
    if (!isAuth) {
      unauthenticate()
      return
    }

    const userInfoStr = window.localStorage.getItem('jwt_decoded')
    if (userInfoStr === null) {
      LogOut()
      unauthenticate()
      return
    }

    const userInfo = JSON.parse(userInfoStr) as UserInfo
    // check if JWT token's expiry time has exceeded current time
    if (timeExceeded(userInfo.exp)) {
      LogOut()
      LogInToGoogle({
        userRedirect: window.location.pathname
      })
      return
    }

    authenticate(token as string, userInfo)
  }, [])

  return (
    <AuthContext.Provider value={{
      ...state,
      authenticate,
      unauthenticate
    }}>
      {props.children}
    </AuthContext.Provider>
  )
}
