import { useState, useEffect } from 'react'
import { useIsMounted } from '../Mounted'
import { EIPSummary, ListedLevel } from '../../eip'

const apiLink = process.env.REACT_APP_API_URL ?? ''

const fetchEIPs = async (): Promise<EIPSummary[]> => {
  const idToken = window.localStorage.getItem('id_token')
  if (idToken === null) {
    throw Error('ID Token not found in local storage')
  }

  const response = await fetch(apiLink + '/eips', {
    method: 'get',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  if (!response.ok) {
    throw Error(`Bad response status: ${response.status} ${response.statusText}`)
  }

  return await response.json() as Promise<EIPSummary[]>
}

interface EIPTableHook {
  eipSummaries?: EIPSummary[];
  loading: boolean;
  errorMessage?: string;
}

export const useEIPTable = (): EIPTableHook => {
  const [eipSummaries, setEipSummaries] = useState<EIPSummary[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const isMounted = useIsMounted()

  useEffect(() => {
    (async (): Promise<void> => {
      const eipSummaries = await fetchEIPs()
      if (isMounted.current) setEipSummaries(eipSummaries)
    })()
      .catch(err => isMounted.current && setErrorMessage((err as Error).toString()))
      .finally(() => isMounted.current && setLoading(false))
  }, [isMounted])

  return { eipSummaries, loading, errorMessage }
}

export const toRepIndicator = (listed?: ListedLevel | boolean): string => {
  switch (listed) {
  case undefined:
    return 'nil'
  case ListedLevel.NotListed:
    return '✅'
  case ListedLevel.Warning:
    return '⚠️'
  case ListedLevel.Listed:
    return '❌'
  case false:
    return '✅'
  case true:
    return '❌'
  }
}
