import { useState } from 'react'
import { EIPSummary, ListedLevel } from '../../eip'

interface EIPFilterHook {
  filteredEIPSummaries?: EIPSummary[];
  showNonProdEIP: boolean;
  filterMenuAnchor: HTMLElement | null;
  ipRepFilter: IPRepFilter;
  searchValues: AutoCompleteOption[];
  searchInputValue: string;
  setSearchInputValue: (newValue: string) => void;
  setSearchValues: (newValues: AutoCompleteOption[]) => void;
  setIPRepFilter: (newValue: IPRepFilter) => void;
  openFilterMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closeFilterMenu: () => void;
  handleChangeFilterNonProdEIP: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface AutoCompleteOption {
  type: string
  label: string
  predicate: (summary: EIPSummary) => boolean
}

export class AddressFilter implements AutoCompleteOption {
  type = 'IP Address'
  label: string
  constructor (label: string) {
    this.label = label
  }

  predicate (summary: EIPSummary): boolean {
    return summary.address === this.label
  }
}

export class NatGatewayFilter implements AutoCompleteOption {
  type = 'NAT Gateway'
  label: string
  constructor (label: string) {
    this.label = label
  }

  predicate (summary: EIPSummary): boolean {
    return summary?.nat_gateway?.id === this.label
  }
}

export class MTAHostnameFilter implements AutoCompleteOption {
  type = 'MTA Hostname'
  label: string
  constructor (label: string) {
    this.label = label
  }

  predicate (summary: EIPSummary): boolean {
    return summary?.nat_gateway?.mta_hostname === this.label
  }
}

export class SubnetIDFilter implements AutoCompleteOption {
  type = 'Subnet ID'
  label: string
  constructor (label: string) {
    this.label = label
  }

  predicate (summary: EIPSummary): boolean {
    return summary?.nat_gateway?.subnet_ids?.includes(this.label) ?? false
  }
}

export class NoSubnetsFilter implements AutoCompleteOption {
  type = 'Subnet ID'
  label = 'No subnets'

  predicate (summary: EIPSummary): boolean {
    return summary?.nat_gateway?.subnet_ids === undefined || summary?.nat_gateway?.subnet_ids?.length === 0
  }
}

export class IPRangeFilter implements AutoCompleteOption {
  type = 'IP Range'
  label: string
  subnetID: string
  prefixLength: number
  constructor (subnetID: string, prefixLength: number) {
    this.label = `${subnetID}/${prefixLength}`
    this.subnetID = subnetID
    this.prefixLength = prefixLength
  }

  ipStringToNumber (addr: string): number {
    const parts = addr.split('.')
    return (parseInt(parts[0]) << 24) + (parseInt(parts[1]) << 16) + (parseInt(parts[2]) << 8) + parseInt(parts[3])
  }

  predicate (summary: EIPSummary): boolean {
    return (this.ipStringToNumber(summary.address) >> (32 - this.prefixLength)) === (this.ipStringToNumber(this.subnetID) >> (32 - this.prefixLength))
  }
}

export type IPRepFilter = 'All' | 'Good' | 'Bad'

export const useEIPFilter = (rawEIPSummaries?: EIPSummary[]): EIPFilterHook => {
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null)
  const [showNonProdEIP, setShowNonProdEIP] = useState<boolean>(false)
  const [searchValues, setSearchValues] = useState<AutoCompleteOption[]>([])
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [ipRepFilter, setIPRepFilter] = useState<IPRepFilter>('All')

  let filteredEIPSummaries = rawEIPSummaries?.filter(summary => summary.nat_gateway?.mta_hostname ?? showNonProdEIP)
  if (ipRepFilter !== 'All') {
    filteredEIPSummaries = filteredEIPSummaries?.filter(
      summary =>
        (ipRepFilter === 'Good' && summary.listed_status === ListedLevel.NotListed) ||
        (ipRepFilter === 'Bad' && summary.listed_status !== ListedLevel.NotListed))
  }

  searchValues.forEach(srchFilter => {
    filteredEIPSummaries = filteredEIPSummaries?.filter(s => srchFilter.predicate(s))
  })

  const openFilterMenu = (event: React.MouseEvent<HTMLButtonElement>): void => setFilterMenuAnchor(event.currentTarget)
  const closeFilterMenu = (): void => setFilterMenuAnchor(null)
  const handleChangeFilterNonProdEIP = (event: React.ChangeEvent<HTMLInputElement>): void => setShowNonProdEIP(event.target.checked)

  return {
    filteredEIPSummaries,
    showNonProdEIP,
    filterMenuAnchor,
    ipRepFilter,
    searchValues,
    searchInputValue,
    setSearchInputValue,
    setSearchValues,
    setIPRepFilter,
    openFilterMenu,
    closeFilterMenu,
    handleChangeFilterNonProdEIP
  }
}
