import { useState, useEffect } from 'react'
import { useIsMounted } from '../Mounted'
import { EIPDetail, EIPEvent } from '../../eip'

const apiLink = process.env.REACT_APP_API_URL ?? ''

const fetchEIP = async (eip: string): Promise<EIPDetail> => {
  const idToken = window.localStorage.getItem('id_token')
  if (idToken === null) {
    throw Error('ID Token not found in local storage')
  }

  const response = await fetch(apiLink + '/eips/' + eip, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  if (!response.ok) {
    throw Error(`Bad response status: ${response.status} ${response.statusText}`)
  }

  return await response.json() as Promise<EIPDetail>
}

interface EIPDetailHook {
  eipDetail?: EIPDetail;
  loading: boolean;
  errorMessage?: string;
}

export const useEIPDetail = (eip: string): EIPDetailHook => {
  const [eipDetail, setEIPDetail] = useState<EIPDetail>()
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const isMounted = useIsMounted()

  useEffect(() => {
    (async (): Promise<void> => {
      const eipDetail = await fetchEIP(eip)
      if (isMounted.current) setEIPDetail(eipDetail)
    })()
      .catch(err => isMounted.current && setErrorMessage((err as Error).toString()))
      .finally(() => isMounted.current && setLoading(false))
  }, [isMounted, eip])

  return { eipDetail, loading, errorMessage }
}

const fetchEIPEvents = async (eip: string): Promise<EIPEvent[]> => {
  const idToken = window.localStorage.getItem('id_token')
  if (idToken === null) {
    throw Error('ID Token not found in local storage')
  }

  const response = await fetch(apiLink + '/eips/' + eip + '/events', {
    method: 'get',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  if (!response.ok) {
    throw Error(`Bad response status: ${response.status} ${response.statusText}`)
  }

  return await response.json() as Promise<EIPEvent[]>
}

interface EIPEventsHook {
  eipEvents?: EIPEvent[];
  loading: boolean;
  errorMessage?: string;
}

export const useEIPEvents = (eip: string): EIPEventsHook => {
  const [eipEvents, setEIPEvents] = useState<EIPEvent[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const isMounted = useIsMounted()

  useEffect(() => {
    (async (): Promise<void> => {
      const events = await fetchEIPEvents(eip)
      if (isMounted.current) setEIPEvents(events)
    })()
      .catch(err => isMounted.current && setErrorMessage((err as Error).toString()))
      .finally(() => isMounted.current && setLoading(false))
  }, [isMounted, eip])

  return { eipEvents, loading, errorMessage }
}
