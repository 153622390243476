import { nanoid } from 'nanoid'

export interface PassThroughState {
  userRedirect?: string;
}

export const LogInToGoogle = (passthroughState: PassThroughState | null): void => {
  const authURL: URL = new URL('https://accounts.google.com/o/oauth2/v2/auth')

  authURL.searchParams.set('state', 'state_parameter_passthrough_value')
  authURL.searchParams.set('redirect_uri', `${window.location.origin}/login/callback`)
  authURL.searchParams.set('client_id', '216130853619-qhmqei9r3te6sf1r4b01ka9nh7e70arq.apps.googleusercontent.com')
  authURL.searchParams.set('response_type', 'id_token')
  authURL.searchParams.set('scope', 'openid email profile')
  authURL.searchParams.set('hd', 'hennge.com')
  if (passthroughState !== null) {
    authURL.searchParams.set('state', JSON.stringify(passthroughState))
  }

  const nonce: string = nanoid()
  window.localStorage.setItem('nonce', nonce) // stores nonce temporarily for comparison
  authURL.searchParams.set('nonce', nonce)

  window.location.assign(authURL.toString())
}

export default LogInToGoogle
