import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

import Dashboard from '../components/pages/Dashboard'
import EIPDetailsPage from '../components/pages/EIPDetail'
import Landing from '../components/pages/Landing'
import NotFound from '../components/pages/NotFound'

import ProtectedRoute from './ProtectedRoute'
import LoginCallback from '../components/LogInCallback'

const Pages = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route element={<ProtectedRoute />}>
        <Route path='eips' element={<Dashboard />} />
        <Route path='eips/:eipID' element={<EIPDetailsPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Pages
