import React from 'react'
import Nav from '../Nav'
import EIPDetails from '../EipDetail'

export const EIPDetailsPage = (): JSX.Element => {
  return (
    <>
      <Nav />
      <EIPDetails />
    </>
  )
}

export default EIPDetailsPage
