import React from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'

import { useEIPDetail, useEIPEvents } from './Data'
import { EIPEvent, ListedLevel } from '../../eip'

import Link from '@mui/material/Link'
import CopyToClipboard from '../CopyToClipboard'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import IconButton from '@mui/material/IconButton'
import { toRepIndicator } from '../EipTable/Data'

const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.2)'
}))

const StyledCardHeaderBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

const StyledEIPTitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(4),
  marginBottom: theme.spacing(6)
}))

const StyledListingTextTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  minHeight: theme.spacing(4)
}))

const StyledDetailsLineBox = styled(Box)(() => ({
  height: '3rem',
  lineHeight: '3rem'
}))

interface RouteParams {
  eipID: string;
}

interface Reputation {
  listed: ListedLevel;
  text: string;
}

interface EventRepChange {
  address: string;
  rbl: string;
  old?: Reputation;
  new?: Reputation;
  time: string;
}

const StyledEventCardTitleBox = styled(Box)(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'baseline'
}))

const StyledRepChangeTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

interface EventCardProps {
  ev: EIPEvent;
}

const EventCard = ({ ev }: EventCardProps): JSX.Element => {
  if (!ev.topic_arn.endsWith('reputation-change')) {
    return (
      <Grid size={{ xs: 8 }}>
        <Card>
          <CardContent>
            <Typography variant="body1">
              <i>Unknown event type...</i>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const repChangePayload = JSON.parse(ev.payload) as EventRepChange

  return (
    <Grid size={{ xs: 6 }}>
      <Card>
        <CardContent>
          <StyledCardHeaderBox>
            <StyledEventCardTitleBox>
              <Typography variant="h6">
                {repChangePayload.rbl}
              </Typography>
            </StyledEventCardTitleBox>
          </StyledCardHeaderBox>
          <Box>
            <Typography>
              {new Date(repChangePayload.time).toLocaleString(navigator.language, { timeZoneName: 'short' })}
            </Typography>
            <Typography>
              {new Date(repChangePayload.time).toLocaleString(navigator.language, { timeZone: 'UTC', timeZoneName: 'short' })}
            </Typography>
          </Box>
          <StyledRepChangeTypography variant="body1">
            {`${toRepIndicator(repChangePayload.old?.listed)} ${'->'} ${toRepIndicator(repChangePayload.new?.listed)}`}
          </StyledRepChangeTypography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export const EIPDetails = (): JSX.Element => {
  const params = useParams<keyof RouteParams>() as RouteParams
  const { eipDetail, loading, errorMessage } = useEIPDetail(params.eipID)
  const { eipEvents, loading: eventsLoading, errorMessage: eventsErrorMessage } = useEIPEvents(params.eipID)

  return (
    <>
      {loading && (
        <StyledBackdrop open={loading}>
          <CircularProgress color="primary" />
        </StyledBackdrop>
      )}
      {!loading && errorMessage === undefined && eipDetail && (
        <Grid container spacing={2} justifyContent="center">
          <Grid size={{ xs: 11 }}>
            <StyledEIPTitleTypography variant="h3">
              { eipDetail.address + (eipDetail.nat_gateway?.mta_hostname ? ' (' + eipDetail.nat_gateway.mta_hostname + ')' : '') }
            </StyledEIPTitleTypography>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={2} size={{ xs: 10 }}>
            <Grid size={{ xs: 12 }}>
              <Typography variant="h3">
              Details
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Card>
                <CardContent>
                  <StyledDetailsLineBox>
                    <Typography display="inline"><b>Address:</b> { eipDetail.address }</Typography>
                    <CopyToClipboard copyText={eipDetail.address} />
                    <Link href={eipDetail.address_link} target='_blank' rel='noopener noreferrer'>
                      <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                    </Link>
                  </StyledDetailsLineBox>
                  <StyledDetailsLineBox>
                    <Typography display="inline"><b>NAT Gateway:</b> { eipDetail.nat_gateway?.id ?? '' }</Typography>
                    { eipDetail.nat_gateway && <CopyToClipboard copyText={eipDetail.nat_gateway.id} /> }
                    { eipDetail.nat_gateway &&
                      <Link href={eipDetail.nat_gateway.link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </StyledDetailsLineBox>
                  <StyledDetailsLineBox>
                    <Typography display="inline"><b>MTA name:</b> { eipDetail.nat_gateway?.mta_hostname ?? '' }</Typography>
                    { eipDetail.nat_gateway?.mta_hostname && <CopyToClipboard copyText={eipDetail.nat_gateway.mta_hostname} /> }
                    { eipDetail.nat_gateway?.mta_link &&
                      <Link href={eipDetail.nat_gateway.mta_link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </StyledDetailsLineBox>
                  <StyledDetailsLineBox>
                    <Typography display="inline"><b>Subnets:</b> { eipDetail.nat_gateway?.subnet_ids?.join(', ') ?? '' }</Typography>
                    { eipDetail.nat_gateway?.subnet_ids?.length === 1 && <CopyToClipboard copyText={eipDetail.nat_gateway.subnet_ids[0]} /> }
                    { eipDetail.nat_gateway?.subnets_link &&
                      <Link href={eipDetail.nat_gateway.subnets_link} target='_blank' rel='noopener noreferrer'>
                        <IconButton aria-label="external link" ><OpenInNewIcon /></IconButton>
                      </Link>
                    }
                  </StyledDetailsLineBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" spacing={2} size={{ xs: 10 }}>
            <Grid size={{ xs: 12 }}>
              <Typography variant="h3">
              Reputation
              </Typography>
            </Grid>
            {eipDetail.reputations.map(rep =>
              <Grid key={rep.rbl.host} size={{ xs: 3 }}>
                <Card>
                  <CardContent>
                    <StyledCardHeaderBox>
                      <Typography variant="h6">
                        {rep.rbl.name ?? rep.rbl.host}
                      </Typography>
                      <Typography>
                        {' ' + toRepIndicator(rep.listed)}
                      </Typography>
                    </StyledCardHeaderBox>
                    <Typography color="textSecondary">
                      {rep.rbl.name && rep.rbl.host}
                    </Typography>
                    <StyledListingTextTypography>
                      {rep.text}
                    </StyledListingTextTypography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <Grid size={{ xs: 10 }}>
            <Grid container direction="column" spacing={2}>
              <Grid size={{ xs: 12 }}>
                <Typography variant="h3">
                  Events (Reputation changes)
                </Typography>
              </Grid>
              {!eventsLoading && eventsErrorMessage === undefined && eipEvents &&
                (eipEvents.length > 0
                  ? <>
                    {eipEvents.map(ev => <EventCard key={ev.message_id} ev={ev} />)}
                  </>
                  : <Grid size={{ xs: 12 }}>
                    <Typography variant="subtitle1">
                      <i>There are no events associated with this EIP.</i>
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {eventsErrorMessage}
      {errorMessage}
    </>
  )
}
